import { replacePhoneNumber } from "../features/call-tracking";

document.addEventListener('DOMContentLoaded', () => {
    if (window.utm_phone_numbers != 'undefined') {
        const telephoneNumbers = document.querySelectorAll('a[href^="tel"]')

        if (telephoneNumbers.length > 0) {
            replacePhoneNumber(telephoneNumbers)
        }
    }
});
