export const replacePhoneNumber = elements => {
    const storageKey = 'phonenumber'
    const searchParams = new URLSearchParams(window.location.search)
    const utmSource = searchParams?.get('utm_source')

    let phoneNumberData = JSON.parse(sessionStorage.getItem(storageKey))

    if (
        (!phoneNumberData && utmSource)
        || (utmSource && phoneNumberData?.source != utmSource)
    ) {
        sessionStorage.removeItem(storageKey)
        phoneNumberData = null

        if (window.utm_phone_numbers[utmSource]) {
            phoneNumberData = window.utm_phone_numbers[utmSource]

            sessionStorage.setItem(storageKey, JSON.stringify(phoneNumberData))
        }
    }

    if (phoneNumberData) {
        elements.forEach(link => {
            if (link.classList.contains('ignore-replace')) {
                return
            }

            link.href = `tel:${ phoneNumberData.href }`

            const span = link.querySelector('span.header__getintouch-phone')
            const elementToReplace = span ?? link

            elementToReplace.innerText = `Call ${phoneNumberData.number}`
        });
    }
}
